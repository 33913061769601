.dashboard-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto !important;
    max-width: 1200px;
    padding: 0 !important
}

.dashboard h3 {
    margin: 0 auto;
    margin-top: 40px;
}

.dashboard .card {
    margin-bottom: 1rem;
    margin: 10px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
    flex: 1 1 0px;
    flex-grow: 1;
    flex-direction: row;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    min-width: 120px;
    min-height: 240px;
    max-width: 380px;
}

.dashboard .card.clickable {
    cursor: pointer;
}

.dashboard .card .card-title {
    font-size: 5rem;
    margin: 0;
}

.dashboard .card .card-text {
    font-size: 1.5rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-variant: small-caps;
}

@media only screen and (max-width: 480px) {
    .dashboard .card .card-text {
        min-height: 60px;
    }
}

.card.bg-warning {
    background-color: rgba(255, 136, 0, 0.9) !important;
    color: rgba(255, 255, 255, 1) !important;
}

.card.bg-success {
    background-color: rgba(0, 126, 51, 0.9) !important;
    color: rgba(255, 255, 255, 1) !important;
}

.card.bg-info {
    background-color: rgba(13, 71, 161, 0.9) !important;
    color: rgba(255, 255, 255, 1) !important;
}

.card.bg-danger {
    color: rgba(255, 255, 255, 1) !important;
    background-color: rgba(204, 0, 0, 0.9) !important;
}

.card.bg-primary {
    background-color: rgba(13, 71, 161, 0.9) !important;
    color: rgba(255, 255, 255, 1) !important;
}

.dashboard.container-fluid,
.dashboard .container-fluid {
    background: none;
}

.graphs {
    margin-top: 30px;
}

.graphs .graph-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
}

.graph .graph-wrapper .MuiChartsLegend-root {
    margin-top: 100px;
}