.total-records {
    display: inline-block;
    font-size: 0.8em;
}

.pagination {
    float: right;
}

.no-records {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
}

.loading {
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
}

.table.grid .col {
    vertical-align: middle;
}

.table.grid .grid-header {
    font-weight: bold;
}

.table.grid .grid-header .col {
    padding: .5rem;
}

.table.grid .grid-item {
    background-color: rgba(0, 0, 0, 0.05);
    border: rgba(222, 226, 230) solid 1px;
    border-left: 0;
    border-right: 0;
    position: relative;
}

.table.grid .grid-item + .grid-item {
    border-top: 0;
}

.table.grid .grid-item.odd {
    background-color: rgba(0, 0, 0, 0.00);
}

.table.grid .grid-item [class^='col-'],
.table.grid .grid-item [class*=' col-'],
.table.grid .grid-item .col {
    padding-left: 0 !important;
}

.grid-cell-content {
    vertical-align: middle;
    align-items: center;
    min-height: 100%;
    padding-left: 0;
    padding-right: 5px;
    width: 100%;
}

.grid-cell-content > span {
    width: 100%;
}

.grid-cell-content hr {
    margin: 3px 0;
}

.block .grid-cell-content {
    display: block;
}

.block .grid-cell-content.d-md-flex {
    display: block;
}

.grid-cell-content.d-md-flex {
    display: inline-flex;
}

.table.grid br {
    display: block;
}

.inside-column-name {
    font-weight: bold;
    margin-right: 3px;
    display: block;
    width: 90%;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    font-size: .9em;
    text-align: left;
    text-decoration: underline;
}

.inside-column-value {
    display: block;
    width: 90%;
    margin-top: 0 !important;
    padding-top: 0 !important;
    text-align: left;
}

.actions div{    
    text-align: right !important;
}

.actions .inside-column-name, 
.actions .inside-column-value {
    width: 10%;
    margin-right: 3px;
    clear: both;
}

.actions a {    
    font-size: 1.3em;
}

.actions a + a {    
    margin-left: 15px;
}

@media (max-width: 576px) {
    .actions div {
        position: absolute;
        top: 38%;
        right: 0px;
        zoom: 1.3;
        text-align: right !important;
    }

    .actions a + a {    
        margin-left: 0;
    }
}

.download-button {
    text-align: right;
    clear: both;
    margin-top: 0;
    margin-bottom: 10px;
    text-decoration: none !important;
}