@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

* {
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
}

html{
  overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6, .accordion-header * {
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
}

h1{
  margin-bottom: 0;
}

hr{
  margin: 0;
  margin-bottom: 20px;
}

input, textarea, select, button {
  font-family: 'Arial', sans-serif;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.invalid-feedback{
  display: block !important;
}

.form-label{
  margin-bottom: 0;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.accordion, .accordion-item{
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  outline: none !important;
  border: 0 !important;
  border-radius: 0 !important;
  -webkit-tap-highlight-color: #111 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  margin-bottom: 0 !important;
  position: relative !important;
  overflow: hidden !important;
}

.accordion-header {
  background: none;
}

.accordion-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.accordion-header button {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  outline: none !important;
  border: 0 !important;
  border-radius: 0 !important;
  background: none !important;
  color: #000 !important;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.container-fluid{
  background-color: rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin: 10px 0;
  border-radius: 5px;
}

.container-fluid.no-background {
  background-color: transparent !important;
}

.form-check {
  margin-top: 3px;
}

.container-fluid.filter-box{
  margin-bottom: 50px;
}

.toast-container {
  position: fixed !important;
  bottom: 0;
  right: 0;
  z-index: 9999;
  padding: 20px;
}

input[type="checkbox"] + label {
  cursor: pointer;
}

.clean-form{
  position: absolute;
  right: 0;
  top: 24px;
  font-size: 12px;
}