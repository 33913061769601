.async-select {
    flex: 1 1 auto;
    min-width: 0;
    width: 1%;
}


.input-group-text + .async-select > div{
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}