header {
  margin-bottom: 20px;
}

.nav-item.dropdown {
  text-decoration: none !important;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-item.dropdown .dropdown-menu {
  margin-top: -2px;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.bg-body-tertiary {
  padding: 0;
}

.nav-link {
  color: #fff !important;
}

.navbar-brand {
  padding: 0;
}

.btn-primary {
  float: right !important;
  display: block;
  margin-right: 1px !important;
  margin: 0 auto !important;
  white-space: nowrap !important;
}

.navbar-nav>* {
  margin-right: 30px !important;
}

.navbar-nav {
  float: left;
}

.btn-wrapper {
  float: right;
}

.spinner-border {
  margin: 0 auto;
  display: block;
}

.greeting-text {
  cursor: default;
}

.navbar .nav-link,
.navbar .nav-item {
  font-weight: bold;
}

.navbar * {
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
}

.navbar .badge {
  zoom: 1.1;
}

@media only screen and (max-width: 480px) {
  header {
    margin-bottom: 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }

  main {
    margin-top: 84px !important;
  }

  .dashboard .card .card-text {
    min-height: 60px;
  }

  .me-auto.navbar-nav {
    float: left;
  }

  .ml-auto.navbar-nav {
    float: right;
    text-align: right;
  }

  .ml-auto.navbar-nav>* {
    margin-right: 0 !important;
  }

  .dropdown-menu {
    position: absolute !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px !important;
  }
}